/*
 TODO: Improve paths
*/
@font-face {
  font-family: 'league_gothiccondensed_italic';
  src: url("./assets/fonts/leaguegothic-condenseditalic-webfont.woff2") format("woff2"), url("./assets/fonts/leaguegothic-condenseditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'league_gothicCnRg';
  src: url("./assets/fonts/leaguegothic-condensedregular-webfont.woff2") format("woff2"), url("./assets/fonts/leaguegothic-condensedregular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'league_gothicitalic';
  src: url("./assets/fonts/leaguegothic-italic-webfont.woff2") format("woff2"), url("./assets/fonts/leaguegothic-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'league_gothicregular';
  src: url("./assets/fonts/leaguegothic-regular-webfont.woff2") format("woff2"), url("./assets/fonts/leaguegothic-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansbold';
  src: url("./assets/fonts/opensans-bold-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansbold_italic';
  src: url("./assets/fonts/opensans-bolditalic-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansextrabold';
  src: url("./assets/fonts/opensans-extrabold-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansextrabold_italic';
  src: url("./assets/fonts/opensans-extrabolditalic-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanslight';
  src: url("./assets/fonts/opensans-light-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanslight_italic';
  src: url("./assets/fonts/opensans-lightitalic-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansregular';
  src: url("./assets/fonts/opensans-regular-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansitalic';
  src: url("./assets/fonts/opensans-regularitalic-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanssemibold';
  src: url("./assets/fonts/opensans-semibold-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanssemibold_italic';
  src: url("./assets/fonts/opensans-semibolditalic-webfont.woff2") format("woff2"), url("./assets/fonts/opensans-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
