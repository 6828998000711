@import "./css/main";

body {
  margin: 0;
  font-family: 'open_sansregular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  font-size: 1.2em;
  font-weight: light;
}
