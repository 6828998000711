// Primary
$purple: #4B2E62;
$lilac: #CFAEDF;
$grey: #545454;

// Secondary
$midPurple: #B880D4;
$white: #FFF;
$midGrey: #A6A6A6;
$lightGrey: #D9D9D9;

// Grayscale
$gray1: #EEE;
$gray2: #DDD;
$medGray1: #BBB;
$medGray2: #888;
$darkGray1: #777;
$darkGray2: #454545;
$black: #000;
