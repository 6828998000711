.clearfix {
  clear: both;
}

.strap-underline {
  font-family: 'league_gothicregular';
  color: $darkGray1;
  margin-top: 15px;
  margin-bottom: 0;

  @media (min-width: 320px) {
    font-size: 54px;
    line-height: 54px;
  }

  @media (min-width: 600px) {
    font-size: 96px;
    line-height: 96px;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 40px;
    background: $midPurple;
    position: absolute;
    z-index: -999;
    left: -25%;

    @media (min-width: 320px) {
      margin-top: 20px;
    }

    @media (min-width: 600px) {
      margin-top: 40px;
      height: 60px;
    }

    @media (min-width: 800px) {
      margin-top: 40px;
    }
  }
}
