* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-links {
  //clip-path: circle(200px at 90% -10%);
  //-webkit-clip-path: circle(200px at 90% -10%);
  transition: all 1s ease-out;
}

.nav-links.open {
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background: #4b2e62;

  //clip-path: circle(100vw);
  //-webkit-clip-path: circle(100vw);
  pointer-events: all;

  button {
    color: #fff;
  }

  img {
    display: none;
  }
}

.nav-links li {
  //opacity: 0;
  list-style: none;
  text-align: center;
  height: 100px;
  clear: both;
}

.nav-links li a {
  text-decoration: none;
  font-size: 50px;
  color: #fff;
  font-weight: bold;

  @media (min-width: 320px) {
    font-size: 30px;
  }
}

.nav-links li:nth-child(1) {
  transition: all 0.5s ease 0.2s;
}

.nav-links li:nth-child(2) {
  transition: all 0.5s ease 0.4s;
}

.nav-links li:nth-child(3) {
  transition: all 0.5s ease 0.6s;
}

// li.fade {
//   opacity: 1;
// }
